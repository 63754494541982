import { datadogRum } from '@datadog/browser-rum';
import React, { useContext, useEffect, useState } from 'react';
import { containersApi } from '../api/api';
import { Box, LinearProgress, Link, Typography } from '@mui/material';
import { joinString } from '../shared/utils';
import { colors } from '../components/shared/ui-extension';
import { groups } from '../constants/p21/role';

export const P21UserContext = React.createContext();

export function useP21UserContext() {
  return useContext(P21UserContext);
}

export const P21UserProvider = ({ children, graphData }) => {
  const [p21User, setP21User] = useState({});
  const [p21Users, setP21Users] = useState([]);
  const [shouldRedirectUser, setShouldRedirectUser] = useState(null);
  const [isAlphaTestingPage, setIsAlphaTestingPage] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getCurrentEnvironment = () => {
    const environment = process.env.NEXT_PUBLIC_DATA_DOG_ENV || 'LOCAL';
    if (!environment.includes('PROD')) {
      return environment;
    }
  };

  const currentEnvironment = getCurrentEnvironment();

  useEffect(() => {
    if (shouldRedirectUser === null || !isAlphaTestingPage || !p21User.id)
      return;

    localStorage.setItem(
      'shouldRedirectUser',
      shouldRedirectUser ? 'true' : 'false'
    );

    const redirect = setTimeout(() => {
      if (window.location.href.includes('order-entry') && shouldRedirectUser) {
        const fullPath = window.location.pathname + window.location.search;
        const redirectUrl = `https://pam-alpha.containers.com/${fullPath}`;
        window.location = redirectUrl;
      }
    }, 2500);
    return () => clearTimeout(redirect);
  }, [p21User, isAlphaTestingPage, shouldRedirectUser]);

  useEffect(() => {
    if (currentEnvironment) {
      return;
    }

    const isTestPage = window.location.href.includes('order-entry');

    setIsAlphaTestingPage(p21User?.is_alpha_tester && isTestPage);
  }, [window.location.href, p21User]);

  const debugUserId = localStorage.getItem('debug-p21-user-id');

  const getUserDetails = async () => {
    try {
      const { data } = await containersApi.get('users/details');
      const { user, users } = data;

      setP21User(
        debugUserId ? users.find((u) => u.id === debugUserId) || user : user
      );
      setP21Users(users);

      setShouldRedirectUser(
        localStorage.getItem('shouldRedirectUser') !== 'false'
      );

      datadogRum.setUser({
        ...user,
        id: user.id, // A unique identifier for the user
        name: joinString([user.first_name, user.last_name], ' '), // The user's name
        email: user.email_address, // The user's email
      });
    } catch (e) {
      console.error(e);
    }
  };

  const isP21UserInGroup = (group) => {
    return group.includes(p21User?.role);
  };

  const isAlphaTester = p21User?.is_alpha_tester;

  const renderBanner = () => {
    if (currentEnvironment) {
      return (
        <Box
          sx={{
            backgroundColor: colors.purple,
            textAlign: 'center',
          }}
        >
          <Typography sx={{ color: 'white', fontSize: '12px' }}>
            <b>{currentEnvironment}</b> Testing -{' '}
            {graphData?.userPrincipalName || ''}
            {debugUserId ? (
              <span style={{ color: 'yellow' }}>
                {' '}
                (Debugging as user{' '}
                <strong>
                  {joinString([
                    debugUserId,
                    p21User?.role,
                    p21User?.contact_role_desc,
                  ])}
                </strong>
                )
              </span>
            ) : (
              ''
            )}
          </Typography>
        </Box>
      );
    }

    return isAlphaTestingPage ? (
      <Box
        sx={{
          backgroundColor: '#e57200',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>
          <Link
            onClick={() => {
              setShouldRedirectUser((prev) => !prev);
            }}
            sx={{ color: 'white' }}
          >
            {shouldRedirectUser
              ? 'Redirecting to PAM Alpha Testing... (Click here to stop)'
              : 'Click to try out the latest features'}
          </Link>
        </Typography>
        {shouldRedirectUser ? <LinearProgress color='warning' /> : false}
      </Box>
    ) : null;
  };

  const permissions = {
    hasPermissionToSeeCost: isP21UserInGroup(groups.unitCostVisible),
    isSupplyChain: isP21UserInGroup(groups.supplyChainVisible),
    isSalesManager: isP21UserInGroup(groups.salesManager),
    isConsultant: p21User?.is_salesrep,
    isSalesMiner: p21User?.is_sales_miner,
  };

  return (
    <P21UserContext.Provider
      value={{
        p21User,
        p21Users,
        setP21User,
        isP21UserInGroup,
        isAlphaTester,
        permissions,
      }}
    >
      {renderBanner()}
      {children}
    </P21UserContext.Provider>
  );
};
